var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "KnowledgeLibraryManageView-wrapper" },
    [
      _c("v-button", {
        staticClass: "btn",
        attrs: { text: "返回" },
        on: { click: _vm.goBack },
      }),
      _c("aside", { staticClass: "tree" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.treeShow,
                expression: "treeShow",
              },
            ],
            staticClass: "treeLeft",
          },
          [
            _c(
              "div",
              { staticClass: "tree-search" },
              [
                _c("v-input", {
                  attrs: {
                    "prefix-icon": "el-icon-search",
                    placeholder: "请输入名称",
                  },
                  model: {
                    value: _vm.filterText,
                    callback: function ($$v) {
                      _vm.filterText = $$v
                    },
                    expression: "filterText",
                  },
                }),
              ],
              1
            ),
            _vm._m(0),
            _c(
              "div",
              { staticClass: "tree-group" },
              [
                _c("button-tree", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeLoading,
                      expression: "treeLoading",
                    },
                  ],
                  key: _vm.keyTree,
                  ref: "buttonTree",
                  attrs: {
                    data: _vm.treeData,
                    defaultProps: _vm.treeProps,
                    btnOps: _vm.btnOps,
                    filterText: _vm.filterText,
                    filterNodeMethod: _vm.filterNodeMethod,
                    defaultExpandedKeys: _vm.defaultExpandedKeys,
                    currentNodeKey: _vm.currentNodeKey,
                    highlightCurrent: true,
                    "expand-on-click-node": false,
                  },
                  on: { nodeClick: _vm.nodeClick },
                }),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "treeSwith", on: { click: _vm.treeSwith } }, [
          _vm.treeShow
            ? _c("img", { attrs: { src: _vm.treeOffImgSrc } })
            : _vm._e(),
          !_vm.treeShow
            ? _c("img", { attrs: { src: _vm.treeOnImgSrc } })
            : _vm._e(),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("header", { staticClass: "contentHeader" }, [
          _c(
            "div",
            { staticClass: "title", attrs: { title: _vm.form.fileName } },
            [_vm._v(_vm._s(_vm.form.fileName || "暂无标题"))]
          ),
          _c(
            "div",
            { staticClass: "label" },
            _vm._l(_vm.form.labelList, function (item) {
              return _c(
                "el-tag",
                { key: item.labelName, staticClass: "mar-l" },
                [_vm._v("\n          " + _vm._s(item.labelName) + "\n        ")]
              )
            }),
            1
          ),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.form.content) } }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tree-title" }, [
      _c("div", { staticClass: "title" }, [_vm._v("目录")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }