<template>
  <div class="KnowledgeLibraryManageView-wrapper">
    <v-button class="btn" text="返回" @click="goBack"></v-button>
    <aside class="tree">
      <div class="treeLeft" v-show="treeShow">
        <!-- 搜索框 -->
        <div class="tree-search">
          <v-input v-model="filterText" prefix-icon="el-icon-search" placeholder="请输入名称" />
        </div>
        <!-- 目录 -->
        <div class="tree-title">
          <div class="title">目录</div>
        </div>
        <!-- 控件树 -->
        <div class="tree-group">
          <button-tree
            ref="buttonTree"
            v-loading="treeLoading"
            :key="keyTree"
            :data="treeData"
            :defaultProps="treeProps"
            :btnOps="btnOps"
            :filterText="filterText"
            :filterNodeMethod="filterNodeMethod"
            :defaultExpandedKeys="defaultExpandedKeys"
            :currentNodeKey="currentNodeKey"
            :highlightCurrent="true"
            :expand-on-click-node="false"
            @nodeClick="nodeClick"
          />
        </div>
      </div>
      <div class="treeSwith" @click="treeSwith">
        <img v-if="treeShow" :src="treeOffImgSrc" />
        <img v-if="!treeShow" :src="treeOnImgSrc" />
      </div>
    </aside>
    <section class="content">
      <header class="contentHeader">
        <div class="title" :title="form.fileName">{{form.fileName || '暂无标题'}}</div>
        <div class="label">
          <el-tag class="mar-l" v-for="item in form.labelList" :key="item.labelName">
            {{ item.labelName }}
          </el-tag>
        </div>
      </header>
      <div class="main"><div v-html="form.content"></div></div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tag } from 'element-ui'
import {
  getViewOrgTreeListURL,
  getDetailURL
} from './api'
import {
  btnOps,
  treeIconMap
} from './map'
import { ButtonTree } from 'components/bussiness/index'
import treeOnImgSrc from './img/treeOn.png'
import treeOffImgSrc from './img/treeOff.png'

Vue.use(Tag)

export default {
  components: {
    'button-tree': ButtonTree
  },
  data () {
    return {
      id: '',
      documentId: '',
      form: {
        id: '',
        fileName: '',
        platform: '',
        labelList: [],
        documentId: '',
        content: ''
      },
      /* tree */
      keyTree: 0,
      treeLoading: false,
      currentData: null, // 按钮点击时保存的节点数据
      currentItem: null, // 按钮点击时保存的按钮数据
      treeOnImgSrc,
      treeOffImgSrc,
      treeShow: true,
      filterText: '',
      btnOps,
      treeData: [],
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        btn: 'operateArr' // 必须
      },
      treeOrgData: {}, // select-button值对应的数据
      currentNodeKey: '',
      defaultExpandedKeys: [],
      treeIconMap
    }
  },
  watch: {
    // currentNodeKey (val, old) {
    //   this.id = val
    //   this.getDetail()
    // }
  },
  created () {
    const { id, documentId } = this.$route.query
    this.id = id
    this.currentNodeKey = id
    this.defaultExpandedKeys = [id]
    this.documentId = documentId
    this.$setBreadcrumb('查看')
    this.getDetail()
  },
  mounted () {
    this.getTreeList()
  },
  methods: {
    goBack () {
      sessionStorage.setItem('documentId', this.documentId)
      this.$router.go(-1)
    },
    /** 树节点相关 */
    getTreeList () { // 获取树节点数据
      this.treeLoading = true
      this.$axios.get(getViewOrgTreeListURL)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            const data = this.dealTreeData(res.data)
            console.log(JSON.stringify(data))
            this.treeData = data
            this.keyTree += 1
          }
        }).finally(() => {
          this.treeLoading = false
        })
    },
    treeSwith () { // 树的显示隐藏
      this.treeShow = !this.treeShow
    },
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },
    nodeClick (data, node, nodeVue) { // 点击树节点文字
      /* data 节点数据对象, node 节点的vue虚拟对象, nodeVue 节点组件本身 */
      if (!data.isCheck) {
        return
      }
      const val = data && (data[this.treeProps.id] || undefined)
      this.defaultExpandedKeys = val ? [val] : []
      this.currentNodeKey = val
      this.id = val
      this.documentId = data[this.treeProps.parentId]
      this.getDetail()
    },
    getDetail () { // 请求文件信息
      this.$axios.get(`${getDetailURL}?id=${this.id}`).then(res => {
        if (res.status === 100) {
          this.update(res.data)
        } else {
          this.$message({
            type: 'success',
            message: res.msg,
            center: true
          })
        }
      })
    },
    dealTreeData (data) {
      let arr = []
      const { children: child, parentId, id, btn } = this.treeProps
      data.forEach(item => {
        let newItem = {}
        const fileList = item.fileList.map(v => ({
          ...v,
          icon: treeIconMap['file'],
          [parentId]: item[id],
          [btn]: [],
          [child]: []
        }))
        newItem = {
          ...item,
          fileList: [],
          icon: treeIconMap['files']
        }
        if (item[child].length > 0) {
          newItem[child] = [...this.dealTreeData(item[child]), ...fileList]
        } else {
          newItem[child] = [...fileList]
        }
        arr.push(newItem)
      })
      return arr
    },
    update (data) {
      this.form.id = data.id
      this.form.fileName = data.fileName
      this.form.labelList = data.labelList.map(v => ({
        id: v.labelId,
        labelName: v.labelName
      }))
      this.form.content = data.content
    }
  }
}
</script>

<style lang="scss" scoped>
.mar-l {
  margin-left: 10px;
}
.KnowledgeLibraryManageView-wrapper {
  display: flex;
  // position: relative;
  height: calc(100% - 20px)!important;
  padding: 0 20px;
  .btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .tree {
    display: flex;
    .treeLeft {
      // flex: 1;
      width: 248px;
      height: 100%;
      display: inline-block;
      position: relative;
      .tree-search {
        text-align: center;
        ::v-deep .v-control {
          width: 100%;
          .el-input {
            width: 100% !important;
            .el-input__inner {
              outline: none;
              padding: 2px 10px 2px 30px;
              height: 33px;
            }
            .el-input__icon {
              line-height: 33px;
            }
            .el-input__suffix {
              line-height: 33px;
            }
          }
        }
      }
      ::v-deep .el-loading-mask {
        z-index: 100!important;
      }
      .tree-title {
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        position: absolute;
        top: 38px;
        left: 0;
        display: flex;
        z-index: 200;
        .title {
          flex: 1;
          font-size: 24px;
          font-weight: bold;
          text-align: left;
          padding-left: 4px;
        }
      }
      .tree-group {
        margin-top: 40px;
        height: calc(100% - 73px);
        width: 240px;
      }
    }
    .treeSwith {
      cursor: pointer;
      display: inline-block;
      position: relative;
      height: 100%;
      vertical-align: top;
      width: 36px;
      img {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 36px;
        height: 80px;
      }
      &-bar {
        width: 8px;
        height: 160px;
        background: linear-gradient(
          to top left,
          rgba(228, 228, 228, 1) 0%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
  .content {
    text-align: left;
    flex: 1;
    .contentHeader {
      width: 100%;
      height: 40px;
      margin-bottom: 5px;
      border-bottom: 1px solid #e3e3e3;
      // box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
      display: flex;
      .title {
        font-size: 28px;
        font-weight: bold;
        line-height: 40px;
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .label {
        flex: 1;
        line-height: 40px;
      }
    }
    .main {
      height: calc(100% - 45px);
      overflow-x: hidden;
      overflow-y: auto;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
}
</style>
